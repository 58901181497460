import { createAsyncThunk, createSlice, Draft, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import offlineUtils from '../../commons/offline/offlineUtils';
import { trainFiltersToParams } from '../../commons/filters/filters';
import { Train, TrainStep } from '../../commons/model/Train';
import trainStepCache from '../../commons/templates/trainStepCache';
import {
  applyTrainDiffs,
  BrakingBulletinDiff,
  createTrainDiff,
  isTraceabilityBrakeTestInvalidateCommand,
  isTraceabilityBrakeTestSignCommand,
  mergeBrakingBulletinDiffs,
  mergeDiffs,
  ReadyForDispatchInvalidateCommand,
  ReadyForDispatchSignCommand,
  TraceabilityInvalidateCommand,
  TraceabilitySignCommand,
  TrainDiffMap,
  TrainStepDiff,
  UserInvalidateCommand,
} from '../../commons/model/TrainDiff';
import { Vehicle } from '../../commons/model/Vehicle';
import { RootState } from '../../commons/reducers/rootReducer';
import { AppDispatch } from '../../commons/store/store';
import apiHelper from '../../api/apiHelper';
import {
  selectOfflineTrains,
  selectTrainDiffs,
  selectTrainStep,
  selectTrainSummaries,
} from '../../commons/selectors/selectors';
import trainCache from '../../commons/templates/trainCache';
import { createEmptyTraceabilityDiff } from '../../commons/model/TraceabilityUtils';
import { createEmptyReadyForDispatchDiff } from '../../commons/model/ReadyForDispatchUtils';
import { toggleVehicleUpdatingFlag, trainStepUpdated } from '../step/trainStepDucks';
import { DateString } from '../../commons/model/common';

/**
 * State of the trains cache, and
 */
export type OfflineTrainsState = {
  loading: boolean;
  saving: boolean;
  /**
   * Changes to the trains since the last save/load.
   */
  diffs: TrainDiffMap;
  /**
   * Additional changes to the trains done while `diffs` is being sent to the server.
   */
  diffsBuffer: TrainDiffMap;
};

const initialState: OfflineTrainsState = {
  loading: false,
  saving: false,
  diffs: {},
  diffsBuffer: {},
};

export const loadTrains = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>('offlineTrains/load', async (_, { getState }) => {
  if (!offlineUtils.isOnline()) {
    return;
  }

  const { filters } = selectTrainSummaries(getState());
  const trains: Train[] = await apiHelper.get('/api/trains', trainFiltersToParams(filters));
  await trainCache.saveTrains(trains);
});

export const persistTrainsIfNeeded = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { saving, diffs } = selectOfflineTrains(getState());
  if (saving || !Object.keys(diffs).length || !offlineUtils.isOnline()) {
    // Nothing to send
    return;
  }
  const persistResult = await dispatch(doPersistTrains());
  if (persistResult.meta.requestStatus === 'fulfilled') {
    // Check if we buffered other changes while saving
    dispatch(persistTrainsIfNeeded());
  }
};

export const onUpdatedSteps = async (updatedSteps: TrainStep[], dispatch: AppDispatch, getState: () => RootState) => {
  await trainStepCache.saveItems(updatedSteps, { clear: false });

  const selectedStep = selectTrainStep(getState());
  if (selectedStep.data?.id) {
    const updatedTrainStep = updatedSteps.find((updatedStep) => updatedStep.id === selectedStep.data!.id);
    if (updatedTrainStep) {
      const diffs = selectTrainDiffs(updatedTrainStep.id)(getState());
      // Save the updated active step, which already has the first diff applied. We still need to apply the diff buffer.
      dispatch(trainStepUpdated(applyTrainDiffs(updatedTrainStep, diffs.slice(1))));
    }
  }
  dispatch(toggleVehicleUpdatingFlag(false));
};

// Weird things happen with type inference when using createAppAsyncThunk here
export const doPersistTrains = createAsyncThunk(
  'offlineTrains/update',
  async (_, { getState, dispatch }): Promise<TrainStep[]> => {
    const { diffs } = selectOfflineTrains(getState() as RootState);
    const body = Object.values(diffs).map((diff) => ({
      ...diff,
      updatedVehicles: Object.values(diff.updatedVehicles),
      updatedBrakingBulletins: Object.values(diff.updatedBrakingBulletins),
    }));

    const updatedSteps: TrainStep[] = await apiHelper.post('/api/persist', body);
    await onUpdatedSteps(updatedSteps, dispatch as AppDispatch, getState as () => RootState);
    return updatedSteps;
  },
);

const selectOrCreateTrainDiff = (state: Draft<OfflineTrainsState>, stepId: string): Draft<TrainStepDiff> => {
  const diffs = state.saving ? state.diffsBuffer : state.diffs;
  if (!diffs[stepId]) {
    diffs[stepId] = createTrainDiff(stepId);
  }
  return diffs[stepId];
};

const offlineTrainsSlice = createSlice({
  name: 'offlineTrains',
  initialState,
  reducers: {
    offlineUpdateCompositionStatus(state, { payload: { id, status, version } }: PayloadAction<TrainStep>) {
      const diff = selectOrCreateTrainDiff(state, id);
      diff.updatedComposition = {
        status,
        version,
      };
    },
    offlineUpdateVehicles(state, { payload }: PayloadAction<Vehicle[]>) {
      for (const vehicle of payload) {
        const diff = selectOrCreateTrainDiff(state, vehicle.stepId);
        diff.updatedVehicles[vehicle.id] = vehicle as Draft<Vehicle>;
      }
    },
    offlineDeleteVehicle(state, action: PayloadAction<Vehicle>) {
      const { stepId, id } = action.payload;
      const diff = selectOrCreateTrainDiff(state, stepId);
      diff.deletedVehicles.push(id);
      // Remove vehicle from updated vehicles to prevent it from being saved after deletion
      delete diff.updatedVehicles[id];
    },
    offlineUpdateBrakingBulletin(
      state,
      { payload: { stepId, diff: bbDiff } }: PayloadAction<{ stepId: string; diff: BrakingBulletinDiff }>,
    ) {
      const trainDiff = selectOrCreateTrainDiff(state, stepId);
      trainDiff.updatedBrakingBulletins[bbDiff.id] = mergeBrakingBulletinDiffs(
        trainDiff.updatedBrakingBulletins[bbDiff.id],
        bbDiff,
      ) as Draft<BrakingBulletinDiff>;
    },
    offlineUpdateTraceability(
      state,
      {
        payload: { stepId, sign, invalidate },
      }: PayloadAction<{
        stepId: string;
        sign?: TraceabilitySignCommand;
        invalidate?: TraceabilityInvalidateCommand;
      }>,
    ) {
      const trainDiff = selectOrCreateTrainDiff(state, stepId);
      if (!trainDiff.updatedTraceability) {
        trainDiff.updatedTraceability = createEmptyTraceabilityDiff();
      }
      if (sign) {
        trainDiff.updatedTraceability.sign = [...trainDiff.updatedTraceability.sign, sign];
      }
      if (invalidate) {
        if (invalidate.userId) {
          trainDiff.updatedTraceability.invalidate = [...trainDiff.updatedTraceability.invalidate, invalidate];
        } else if (isTraceabilityBrakeTestInvalidateCommand(invalidate)) {
          // delete offline brake test signature
          trainDiff.updatedTraceability.sign = trainDiff.updatedTraceability.sign.filter(
            (signCommand) =>
              !isTraceabilityBrakeTestSignCommand(signCommand) ||
              (signCommand.brakeTestType !== invalidate.brakeTestType &&
                signCommand.brakeTestDateTime !== invalidate.brakeTestDateTime),
          );
        } else {
          // delete offline signature
          trainDiff.updatedTraceability.sign = trainDiff.updatedTraceability.sign.filter(
            (signCommand) => signCommand.type !== invalidate.type,
          );
        }
      }
    },
    offlineUpdateReadyForDispatch(
      state,
      {
        payload: { stepId, sign, invalidate },
      }: PayloadAction<{
        stepId: string;
        sign?: ReadyForDispatchSignCommand;
        invalidate?: ReadyForDispatchInvalidateCommand;
      }>,
    ) {
      const trainDiff = selectOrCreateTrainDiff(state, stepId);
      if (!trainDiff.updatedReadyForDispatch) {
        trainDiff.updatedReadyForDispatch = createEmptyReadyForDispatchDiff();
      }
      if (sign) {
        trainDiff.updatedReadyForDispatch.sign = sign;
      }
      if (invalidate) {
        if (invalidate.date) {
          trainDiff.updatedReadyForDispatch.invalidate = [...trainDiff.updatedReadyForDispatch.invalidate, invalidate];
        } else {
          // delete offline signature
          trainDiff.updatedReadyForDispatch.sign = undefined;
        }
      }
    },
    offlineUpdateMissionEnd(
      state,
      {
        payload: { stepId, sign, invalidate },
      }: PayloadAction<{
        stepId: string;
        sign?: DateString;
        invalidate?: UserInvalidateCommand;
      }>,
    ) {
      const trainDiff = selectOrCreateTrainDiff(state, stepId);
      if (!trainDiff.updatedMissionEnd) {
        trainDiff.updatedMissionEnd = { invalidate: [] };
      }
      if (sign) {
        trainDiff.updatedMissionEnd.sign = sign;
      }
      if (invalidate) {
        if (invalidate.userId) {
          trainDiff.updatedMissionEnd.invalidate = [...trainDiff.updatedMissionEnd.invalidate, invalidate];
        } else {
          // delete offline signature
          trainDiff.updatedMissionEnd.sign = undefined;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doPersistTrains.pending, (state) => {
        state.saving = true;
      })
      .addCase(doPersistTrains.fulfilled, (state) => {
        state.saving = false;
        state.diffs = state.diffsBuffer;
        state.diffsBuffer = {};
      })
      .addCase(doPersistTrains.rejected, (state) => {
        state.saving = false;
        state.diffs = mergeDiffs(state.diffs, state.diffsBuffer) as Draft<TrainDiffMap>;
        state.diffsBuffer = {};
      })
      .addCase(loadTrains.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(isAnyOf(loadTrains.fulfilled, loadTrains.rejected), (state) => {
        state.loading = false;
      });
  },
});
export const {
  offlineUpdateCompositionStatus,
  offlineUpdateVehicles,
  offlineDeleteVehicle,
  offlineUpdateBrakingBulletin,
  offlineUpdateTraceability,
  offlineUpdateReadyForDispatch,
  offlineUpdateMissionEnd,
} = offlineTrainsSlice.actions;
export default offlineTrainsSlice.reducer;
