import DamageReportEntity from '../../commons/entity/DamageReportEntity';
import { addOrUpdateDamageReportInCache } from '../damage-reports-table/damageReportsCacheActions';
import { initialize } from 'redux-form';
import offlineUtils from '../../commons/offline/offlineUtils';
import apiHelper from '../../api/apiHelper';

export const LOAD_DAMAGE_REPORT_START = 'LOAD_DAMAGE_REPORT_START';
export const LOAD_DAMAGE_REPORT_SUCCESS = 'LOAD_DAMAGE_REPORT_SUCCESS';
export const LOAD_DAMAGE_REPORT_FAILURE = 'LOAD_DAMAGE_REPORT_FAILURE';
export const LOAD_DAMAGE_REPORT_OFFLINE = 'LOAD_DAMAGE_REPORT_OFFLINE';
export const HIDE_IS_IN_LABELS_FROM_DATE = '2025-01-31';

const loadDamageReportOffline = () => ({
  type: LOAD_DAMAGE_REPORT_OFFLINE,
});

export const loadDamageReportFailure = (statusCode) => ({
  type: LOAD_DAMAGE_REPORT_FAILURE,
  payload: { statusCode },
});

export const loadDamageReportSuccess = (data) => (dispatch) => {
  const damageReport = new DamageReportEntity(data);
  dispatch({
    type: LOAD_DAMAGE_REPORT_SUCCESS,
    payload: damageReport,
  });
  dispatch(addOrUpdateDamageReportInCache(damageReport));
};

export const loadDamageReport = (id) => async (dispatch, getState) => {
  // Preload with content in cache if available
  const cachedDamageReport =
    new DamageReportEntity(getState().damageReports.cache.loadByKeyFromCache(id)) || new DamageReportEntity();
  dispatch(initialize('damageReportForm', cachedDamageReport));
  dispatch({ type: LOAD_DAMAGE_REPORT_START, payload: cachedDamageReport });

  // Don't try to load from server if offline or new
  if (!offlineUtils.isOnline() || (cachedDamageReport && cachedDamageReport.new)) {
    dispatch(loadDamageReportOffline());
    return null;
  }

  try {
    const persistedDamageReports = await apiHelper.get(`/api/damageReports/${id}`);
    dispatch(loadDamageReportSuccess(persistedDamageReports));
    dispatch(initialize('damageReportForm', persistedDamageReports));
  } catch (error) {
    if (offlineUtils.isOnline()) {
      dispatch(loadDamageReportFailure(error.httpStatus));
    } else {
      // Connection lost
      dispatch(loadDamageReportOffline());
    }
  }
};
