import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openConfirmDialog } from '../../commons/components/modal/confirmModalDucks';
import { requestDeleteWagon } from '../pre-advice/preAdviceDucks';
import PreAdviceWagonForm from './PreAdviceWagonForm';
import PreAdviceWagonEntity from '../../commons/entity/PreAdviceWagonEntity';
import Switch2 from '../../commons/components/switch2/Switch2';
import { requestVerifyWagon } from './preAdviceWagonDucks';
import { getInitialValues } from './preAdviceWagonFormUtils';
import { Link } from 'react-router-dom';
import { useViewportWidth } from '../../commons/responsive/hooks';
import { getPreAdviceWagonIsUpdating } from '../../commons/selectors/selectors';

const PreAdviceWagonDetails = ({ preAdvice, canModifyPreAdvices, wagon }) => {
  const dispatch = useDispatch();
  const { isMobile } = useViewportWidth();
  const updating = useSelector(getPreAdviceWagonIsUpdating);

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: `Supprimer le wagon ${wagon.getFormattedRegistrationNumber()} ?`,
        actionText: 'Oui, supprimer',
        action: requestDeleteWagon(wagon.id, true),
        actionClass: 'danger',
      }),
    );
  };

  return (
    <div className="vehicle-edit" onClick={() => false}>
      <div className="vehicle-edit-form">
        <div className="vehicle-edit-header">
          {isMobile && (
            <Link className="btn btn-icon btn-basic wagon-empty" to={`/pre-advices/${wagon.preAdviceId}?tab=vehicles`}>
              <strong>&lt;</strong>
            </Link>
          )}
          <h1 className="title">Wagon</h1>
          <Switch2
            id="switch-validate"
            className="switch-validate"
            showLock={true}
            labelOff="À vérifier"
            labelOn="OK"
            value={wagon.isValidated()}
            onChange={() => {
              // Small delay to give the Blur field handlers time to save the wagon
              setTimeout(() => dispatch(requestVerifyWagon()), 50);
            }}
            disabled={!canModifyPreAdvices || preAdvice.isReceivedByVfli() || updating}
          />
          {canModifyPreAdvices && !wagon.isValidated() && (
            <button className="btn btn-round remove" onClick={onDelete} type="button" />
          )}
        </div>
        <PreAdviceWagonForm
          initialValues={getInitialValues(wagon)}
          canModifyPreAdvices={canModifyPreAdvices}
          validate={PreAdviceWagonEntity.validate(preAdvice.wagons.length)}
          wagon={wagon}
          nbWagons={preAdvice.wagons.length}
        />
      </div>
    </div>
  );
};

export default PreAdviceWagonDetails;
