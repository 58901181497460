import { touch } from 'redux-form';

import VehiclesUtils from '../../commons/model/VehiclesUtils';
import { startToast } from '../../commons/components/toast/toastDucks';
import { ConfirmModalFormValues } from '../../commons/components/modal/confirmModalDucks';
import hasValidationErrors, {
  getValidationMessage,
  mergeValidationErrors,
  validateEngine,
  validateWagon,
} from '../../commons/validation/validationUtils';
import { browserHistory } from '../../commons/routes/routerHistoryConfig';
import { engineFormName, wagonFormName } from '../../commons/components/vehicles/edit/vehicleEditUtils';
import {
  offlineDeleteVehicle,
  offlineUpdateCompositionStatus,
  offlineUpdateVehicles,
  persistTrainsIfNeeded,
} from '../offline/offlineTrainsDucks';
import { TrainStep } from '../../commons/model/Train';
import { toggleVehicleUpdatingFlag, trainStepUpdated } from './trainStepDucks';
import { RootState } from '../../commons/reducers/rootReducer';
import { createNewEngine, createNewWagon, Engine, isWagon, Vehicle, Wagon } from '../../commons/model/Vehicle';
import { applyTrainDiffs } from '../../commons/model/TrainDiff';
import { AppDispatch } from '../../commons/store/store';
import { ValidationStatus } from '../../commons/model/common';
import TrainUtils from '../../commons/model/TrainUtils';
import helpers from '../../commons/helpers/helpers';
import { RegisteredFieldState } from 'redux-form/lib/reducer';
import { selectTrainDiffs, selectTrainStep } from '../../commons/selectors/selectors';

/*
 * Actions
 */

export const afterVehiclesUpdated = (step: TrainStep) => (dispatch: AppDispatch, getState: () => RootState) => {
  if (step.status === 'EMPTY') {
    dispatch(offlineUpdateCompositionStatus({ ...step, status: 'NOT_VALIDATED' }));
  }
  const diffs = selectTrainDiffs(step.id)(getState());

  dispatch(trainStepUpdated(applyTrainDiffs(step, diffs)));
  dispatch(persistTrainsIfNeeded());
};

export const addWagons =
  ({ nbWagons }: ConfirmModalFormValues<{ nbWagons: 'number' }>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const step = selectTrainStep(getState()).data!;

    const wagons = [];

    let indexInArrayToInsertWagon = step.vehicles.length;
    let position = step.vehicles.length;
    let index = 1;

    const lastWagonPositionInArray = VehiclesUtils.getLastWagonPositionInArray(step.vehicles);
    if (lastWagonPositionInArray !== null) {
      indexInArrayToInsertWagon = lastWagonPositionInArray + 1;
      const lastWagon = step.vehicles[lastWagonPositionInArray] as Wagon;
      position = lastWagon.position + 1;
      index = lastWagon.index + 1;
    }

    const vehicles = [...step.vehicles];

    if (Number.parseInt(nbWagons!, 10)) {
      for (const i of Array(nbWagons).keys()) {
        const wagon = createNewWagon(step, position + i, index + i);
        wagons.push(wagon);
        vehicles.splice(indexInArrayToInsertWagon + i, 0, wagon);
      }
    }

    const vehiclesToUpdate = VehiclesUtils.computeVehiclesPositions(vehicles).concat(wagons);

    dispatch(offlineUpdateVehicles(vehiclesToUpdate));
    dispatch(afterVehiclesUpdated(step));
  };

export const addEngine = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const step = selectTrainStep(getState()).data!;

  let indexInArrayToInsertEngine = step.vehicles.length;
  let position = indexInArrayToInsertEngine;

  const firstWagonPositionInArray = VehiclesUtils.getFirstWagonPositionInArray(step.vehicles);
  if (firstWagonPositionInArray !== null) {
    indexInArrayToInsertEngine = firstWagonPositionInArray;
    const firstWagon = step.vehicles[firstWagonPositionInArray];
    position = firstWagon.position;
  }

  const engine = createNewEngine(step, position);

  const vehicles = [...step.vehicles];
  vehicles.splice(indexInArrayToInsertEngine, 0, engine);

  const vehiclesToUpdate = VehiclesUtils.computeVehiclesPositions(vehicles);
  vehiclesToUpdate.push(engine);

  dispatch(offlineUpdateVehicles(vehiclesToUpdate));
  dispatch(afterVehiclesUpdated(step));
};

export const updateWagon =
  (wagon: Wagon, { id, status, ...propertiesToUpdate }: Partial<Wagon>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const trainStepState = selectTrainStep(getState());
    const step = trainStepState.data!;
    dispatch(toggleVehicleUpdatingFlag(true));
    const wagonUpdated: Wagon = helpers.with(
      wagon,
      propertiesToUpdate,
      wagon.status === 'EMPTY' && !Object.hasOwnProperty.call(propertiesToUpdate, 'position')
        ? { status: 'NOT_VALIDATED' }
        : {},
    );

    let vehiclesToUpdate = [];
    if (Object.hasOwnProperty.call(propertiesToUpdate, 'index') && propertiesToUpdate.index !== wagon.index) {
      // Wagon reordered by editing its index field
      vehiclesToUpdate = VehiclesUtils.reorderWagons(step.vehicles, wagonUpdated);
    } else if (Object.hasOwnProperty.call(propertiesToUpdate, 'position')) {
      // Wagon reordered by drag&drop
      vehiclesToUpdate = VehiclesUtils.reorderVehicles(step.vehicles, wagonUpdated);
    } else {
      vehiclesToUpdate.push(wagonUpdated);
    }

    dispatch(offlineUpdateVehicles(vehiclesToUpdate));
    dispatch(afterVehiclesUpdated(step));
  };

export const updateEngine =
  (engine: Engine, { id, ...propertiesToUpdate }: Partial<Engine>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const trainStepState = selectTrainStep(getState());
    const step = trainStepState.data!;
    dispatch(toggleVehicleUpdatingFlag(true));

    const engineUpdated: Engine = {
      ...engine,
      ...propertiesToUpdate,
      ...(engine.status === 'EMPTY' && !Object.hasOwnProperty.call(propertiesToUpdate, 'position')
        ? { status: 'NOT_VALIDATED' }
        : {}),
    };

    let vehiclesToUpdate = [];
    if (Object.hasOwnProperty.call(propertiesToUpdate, 'position')) {
      vehiclesToUpdate = VehiclesUtils.reorderVehicles(step.vehicles, engineUpdated);
    } else {
      vehiclesToUpdate.push(engineUpdated);
    }

    dispatch(offlineUpdateVehicles(vehiclesToUpdate));
    dispatch(afterVehiclesUpdated(step));
  };

export const deleteWagon =
  (wagon: Wagon, navigateAfterDeletion: boolean) => () => (dispatch: AppDispatch, getState: () => RootState) => {
    const step = selectTrainStep(getState()).data!;

    dispatch(offlineDeleteVehicle(wagon));
    dispatch(afterVehiclesUpdated(step));
    dispatch(startToast({ text: 'Le wagon a été supprimé', className: 'success' }));

    if (navigateAfterDeletion) {
      browserHistory.push(TrainUtils.linkToTrainStep(wagon.stepId, 'vehicles'));
    }
  };

export const deleteEngine =
  (engine: Engine, navigateAfterDeletion: boolean) => () => (dispatch: AppDispatch, getState: () => RootState) => {
    const step = selectTrainStep(getState()).data!;

    dispatch(offlineDeleteVehicle(engine));
    dispatch(afterVehiclesUpdated(step));
    dispatch(startToast({ text: 'La locomotive a été supprimée', className: 'success' }));

    if (navigateAfterDeletion) {
      browserHistory.push(TrainUtils.linkToTrainStep(engine.stepId, 'vehicles'));
    }
  };

const updateVehicleStatus =
  (step: TrainStep, vehicle: Vehicle, status: ValidationStatus, message: string) => (dispatch: AppDispatch) => {
    const vehicleUpdated: Vehicle = { ...vehicle, status };

    dispatch(offlineUpdateVehicles([vehicleUpdated]));
    dispatch(afterVehiclesUpdated(step));

    dispatch(startToast({ text: message, className: 'success' }));
  };

const processVerification =
  (step: TrainStep, validationErrors: object, vehicle: Vehicle) => (dispatch: AppDispatch) => {
    if (!hasValidationErrors(validationErrors)) {
      dispatch(updateVehicleStatus(step, vehicle, 'VALIDATED', 'Vérification réussie'));

      if (step.vehicles.filter((item) => item.id !== vehicle.id).every((item) => item.status === 'VALIDATED')) {
        dispatch(
          startToast({
            text: 'Tous les véhicules ont été vérifiés. Pensez à valider la composition',
            className: 'success',
            higher: true,
          }),
        );
      }
    } else {
      let message = 'La vérification a échoué : ';
      message += getValidationMessage(validationErrors);
      dispatch(startToast({ text: message, className: 'error' }));
    }
  };

export const verifyOrUnverifyVehicle = (vehicleId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const step = selectTrainStep(getState()).data!;
  const vehicle = VehiclesUtils.findVehicle(step.vehicles, vehicleId)!;

  if (TrainUtils.isStatusValidated(step)) {
    dispatch(
      startToast({
        text: 'Pour modifier un véhicule, veuillez au préalable déverrouiller la composition',
        className: 'error',
      }),
    );
  } else if (vehicle.status === 'VALIDATED') {
    dispatch(updateVehicleStatus(step, vehicle, 'NOT_VALIDATED', "Le véhicule n'est plus vérifié"));
  } else if (isWagon(vehicle)) {
    // Validate form AND underlying entity to be able to see last incorrect changes,
    // and catch cases where the entity was not correctly updated
    const wagonForm = getState().form[wagonFormName];
    // Fix the redux-form type mapping
    const registeredFields = wagonForm.registeredFields as any as Record<string, RegisteredFieldState>;
    const nbWagon = VehiclesUtils.getNbWagon(step.vehicles);
    const formValidationErrors = validateWagon(wagonForm.values as Wagon, step.combinedMarket, nbWagon);
    const entityValidationErrors = validateWagon(vehicle, step.combinedMarket, nbWagon);
    const validationErrors = mergeValidationErrors(formValidationErrors, entityValidationErrors);
    dispatch(processVerification(step, validationErrors, vehicle));
    dispatch(touch(wagonFormName, ...Object.values(registeredFields).map((field) => field.name)));
  } else {
    // Validate form, not underlying entity to be able to see last incorrect changes
    const engineForm = getState().form[engineFormName];
    const registeredFields = engineForm.registeredFields as any as Record<string, RegisteredFieldState>;
    const formValidationErrors = validateEngine(engineForm.values as Engine);
    const entityValidationErrors = validateEngine(vehicle);
    const validationErrors = mergeValidationErrors(formValidationErrors, entityValidationErrors);
    dispatch(processVerification(step, validationErrors, vehicle));
    dispatch(touch('engineForm', ...Object.values(registeredFields).map((field) => field.name)));
  }
};

export const inverseWagonsOrder = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const step = selectTrainStep(getState()).data!;
  const wagons = VehiclesUtils.getWagons(step.vehicles);
  const lastIndex = wagons.length - 1;
  const updatedWagons = wagons.map((wagon, i) => ({
    ...wagon,
    index: wagons[lastIndex - i].index,
    position: wagons[lastIndex - i].position,
  }));
  dispatch(offlineUpdateVehicles(updatedWagons));
  dispatch(afterVehiclesUpdated(step));

  dispatch(
    startToast({
      text: 'Composition inversée',
      className: 'success',
    }),
  );
};
