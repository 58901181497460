import { useEffect, useState } from 'react';
import apiHelper from '../../api/apiHelper';
import { HIDE_IS_IN_LABELS_FROM_DATE } from '../../damage-reports/damage-report/damageReportLoadActions';
import offlineUtils from '../offline/offlineUtils';

export const useHideIsInLabelsFromDate = () => {
  const [hideIsInLabelsFromDate, setDate] = useState(HIDE_IS_IN_LABELS_FROM_DATE);

  useEffect(() => {
    void getDateFromServerIfOnline();
  }, []);

  const getDateFromServerIfOnline = async () => {
    if (offlineUtils.isOnline()) {
      // If online, get from server
      const date = await apiHelper.get(`/api/damageReports/get-hide-is-in-labels-from-date`);
      setDate(date);
    }
  };

  return { hideIsInLabelsFromDate };
};
